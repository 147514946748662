

export default {
  "app": {
    "verified": "Verified",
    "not-verified": "Not verified",
    "verify": "Verify",
    "change": "Change",
    "finish": "Finish",
    "info-tooltip": "Info",
    "footer-dekra-title": "DEKRA Arbeit Group",
    "footer-part-of": ", part of",
    "organization": "Organization",
    "theme": "App theme",
    "cancel": "Cancel",
    "crop-and-save": "Crop and Save",
    "confirm": "Confirm",
    "org-settings": "Org. settings",
    "invite-user": "Invite user",
    "profile": "Profile",
    "settings": "Settings",
    "logout": "Logout",
    "search": "Search",
    "sort-by": "Sort by",
    "ascending": "Ascending",
    "ascending-short": "Asc",
    "descending": "Descending",
    "descending-short": "Desc",
    "filter-btn": "Apply",
    "reset-filter": "Reset filter",
    "info": "Info",
    "not-this-user": "Not this user?",
    "continue": "Continue",
    "enter-code": "Enter code",
    "clear": "Clear",
    "save": "Save",
    "add-new": "Add new",
    "no-data": "No data available",
    "edit": "Edit",
    "delete": "Delete",
    "back": "Back",
    "loading": "Loading...",
    "collapse": "Collapse",
    "send": "Send",
    "version": "Application version",
    "power-mode-btn": "Control panel",
    "go-back": "Go back",
    "my-account": "My account",
    "fullscreen": "Fullscreen mode",
    "grid-label": "Grid",
    "table-label": "Table",
    "grid-tooltip": "Grid display",
    "table-tooltip": "Table display",
    "export": "Export to csv",
    "work-in-progress": "Work in progress"
  },
  "auth": {
    "login": "Login",
    "submit": "Submit",
    "back-to-login": "Back to login",
    "forgot-password": "Forgot password",
    "reset-password": "Reset password",
    "set-password": "Set password",
    "reset-password-expired": "Reset password token expired",
    "set-password": "Set your password",
    "set-password-expired": "Set password token expired",
  },
  "nav-bar": {
    "home-title": "Home",
    "home-caption": "App Dashboard",
    "contacts-manager-title": "Contact management",
    "contacts-manager-caption": "Manage contacts in organization",
    "contacts-title": "Contacts",
    "contacts-caption": "Contacts list",
    "pools-title": "Pools",
    "custom-attributes-title": "Custom Attributes",
    "tags-title": "Tags",
    "content-creator-title": "Content creator",
    "form-builder-title": "Form builder",
    "page-builder-title": "Page builder",
    "users-manager-title": "Users management",
    "users-manager-caption": "Manage users in organization",
    "users-title": "Users",
    "users-caption": "Users list",
    "settings-title": "Settings",
    "settings-caption": "Account preferences",
    "collapse-title": "Collapse",
    "collapse-caption": "Collapse sidebar",
    "power-mode-title": "Admin panel",
    "power-mode-caption": "Control panel"
  },
  "app-fields": {
    "first-name-label": "First Name",
    "last-name-label": "Last Name",
    "username-label": "Username",
    "email-label": "Email",
    "phone-label": "Phone number",
    "bio-label": "Bio (Write a short introduction)"
  },
  "delete-modal": {
    "confirm-delete": "Confirm Delete",
    "delete-text": "Are you sure you want to delete this item?",
    "confirm-restore": "Confirm Restore",
    "restore-text": "Are you sure you want to restore this item?"
  },
  "reload-modal": {
    "confirm-reload": "Confirm Leaving the page",
    "reload-text": "Are you sure you want to leave the page? Your changes might be lost!",
  },
  "welcome-page": {
    "welcome-title": "Welcome to",
    "powered-by": "Powered by"
  },
  "users": {
    "invite-user": "Invite user",
    "users-list-title": "Users in organization",
    "users-list": "Users",
    "invite-user-title": "Invite User in Organization",
    "filters": "Filters",
    "invite-user-page": {
      "email-info": "Enter the email address of the person you want to invite",
      "username-info": "Provide a username for this person",
      "roles": "Assign roles",
      "roles-info": "Select the roles that best suit the user's responsibilities within this organization.",
      "not-valid-email": "Email is not valid",
      "not-valid-phone": "Phone number is not valid",
      "not-valid-username": "Username is not valid. Please use only lowercase letters, numbers, and special characters.",
      "user-in-org": "This user is already a member of the organization.",
      "success-page": "You have successfully invited the user to this organization."
    },
    "loading-users": "Loading users...",
    "admin-title": "Administrator",
    "remove-user-from-org": "Remove User from Organization",
    "abort-user-invitation": "Abort User Invitation",
    "resend-invitation": "Send the invitation again",
    "invitation-accepted": "Invitation accepted",
    "invitation-declined": "Invitation declined",
    "invitation-pending": "Invitation pending",
    "edit-user": "Edit user",
    "user-profile": "User profile",
    "edit": {
      "email-label": "User email",
      "username-label": "User username"
    },
    "last-updated": "Last updated",
  },
  "contacts": {
    "contacts-list-title": "Contacts within organization",
    "contacts-list": "Contacts",
    "create-contact": "Create contact",
    "create-contact-title": "Create new contact",
    "contacts-import-title": "Import contacts",
    "import-bulk": "Import bulk",
    "filters": "Filters",
    "loading-contacts": "Loading contacts...",
    "edit-contact": "Edit contact",
    "contact-profile": "Contact profile",
    "last-updated": "Last updated",
  },
  "roles": {
    "title": "Roles",
    "table": {
      "name": "Name",
      "display-name": "Display name",
      "description": "Description"
    }
  },
  "profile": {
    "reset-password-title": "Reset your password",
    "reset-password-info": "Last password changed at ",
    "reset-password-btn": "Reset password",
    "reset-password-label-new-password": "New Password",
    "reset-password-label-confirm-password": "Confirm Password",
    "reset-password-new-password-not-valid": "Password field is required and must be at least 8 characters long",
    "reset-password-new-password-confirmation-not-valid": "Password confirmation field is required and must match the password field",
    "reset-password-error": "Verify Your Phone Number to change Password",
    "reset-password-form-title": "Create New Password",
    "reset-password-form-subtitle": "Set strong password to keep secure your account.",
    "reset-password-hint": "Must be at least 8 characters,it must also include at least one upper case letter, one lower case letter, one number and one special character.",
    "reset-password-succes-title": "Successul password reset!",
    "reset-password-succes-info": "You can now use your new password to log in to your account.",
    "reset-password-save-btn": "Save",
    "first-name-not-valid": "First name is not valid",
    "last-name-not-valid": "Last name is not valid",
    "organization-tab-org-switch-title": "Switch organization",
    "organization-tab-org-switch-tooltip": "Switch to this organization",
    "organization-tab-org-leave-tooltip": "Leave this organization",
    "organization-invitation-accept": "Accept",
    "organization-invitation-decline": "Decline",
    "organization-tab-current-org": "Current organization",
    "tab-details-title": "Details",
    "tab-security-title": "Security",
    "tab-organizations-title": "Organizations",
    "header-updated-at": "Updated At:",
    "add-phone-number": "Add phone number",
    "add-email": "Add email",
    "roles-label": "Roles"
  },
  "otp-page": {
    "otp-title": "One-Time code Authentication",
    "otp-subtitle": "A verification code has been sent to ",
    "keycloak-otp-subtitle": "Open one of Mobile Authenticator apps, find Your account and enter the one-time code provided by the application.",
    "otp-info": "Please enter 6-digit code.",
    "otp-remaining-time": "Remaining time: ",
    "otp-verify-btn": "Verify",
    "otp-verify-problems-info": "Did not receive the code?",
    "otp-resend-code-text": "Resend code",
    "verification-code-label": "One-time code",
    "cooldown-title": "Cooldown...",
    "resend-cooldown-info": "Wait for cooldown..."
  },
  "organizations": {
    "info-title": "Invitation to",
    "info-subtitle": "You have been invited to join a",
    "info-inviter-label": "Invitation sent by:",
    "info-roles-assigned-label": "Roles assigned:",
    "info-roles-assigned-subtitle": "roles assigned"
  },
  "helpers": {
    "back-home-btn": "Go back to Home",
    "no-org-page-title": "You are not member of any organization.",
    "message-field-label": "Send us message if you need something.",
    "message-placeholder": "Message",
    "no-permistions-page-title": "Access Denied!",
    "no-permissions-page-subtitle": "You don't have the permissions to view this page. Contact the admin for information!",
    "success-action": "You have successfully completed the action.",
    "error-500-title": "Internal Server Error",
    "error-500": "Error 500",
    "error-404": "Error 404",
    "error-401": "Error 401",
    "error-503": "Error 503",
    "error-503-title": "Service Unavalable",
    "error-503-subtitle": "Sorry, the page is temporarily unavailable.",
    "error-500-subtitle": "We apologise and are fixing the problem. Please try again later.",
    "error-404-title": "Something went wrong",
    "error-404-subtitle": "Sorry we were unable to find this page."
  },
  "settings": {
    "general-title": "General",
    "appearance-title": "Appearance",
    "theme-title": "Select Theme",
    "theme-subtitle": "Customization acording to your preferences.",
    "theme-toggle": "Toggle theme",
    "theme-hint": "Choose the theme you'd like to use.",
    "grid-title": "Select your display style",
    "grid-subtitle": "Customization acording to your preferences.",
    "grid-toggle": "Toggle grid/table display",
    "grid-hint": "Choose the display style you'd like to use.",
    "primary-color-title": "Primary Color",
    "primary-color-subtitle": "Choose the primary color you'd like to use.",
    "primary-color-hint": "Selected primary color will be shown across the app.",
    "date-format-title": "Date Format",
    "date-format-subtitle": "Choose the date format you'd like to use.",
    "date-format-hint": "Selected date format will be shown across the app.",
    "time-format-title": "Time Format",
    "time-format-subtitle": "Choose the time format you'd like to use.",
    "time-format-hint": "Selected time format will be shown across the app.",
    "notification-title": "Notification",
    "email-notification-title": "Email notification",
    "email-notification-subtitle": "Substance can send you email notification for any new direct message",
    "chat-notification-title": "Chat notification",
    "chat-notification-subtitle": "Messages, mentions and reactions.",
    "show-preview-message": "Show message preview",
    "play-sound-notification": "Play sound for incoming notifications",
    "templates-title": "Templates",
    "language-title": "Language",
    "language-subtitle": "Manage your language preferences",
    "language-hint": "Choose the language you'd like to use.",
    "time-zone-title": "Time-zone",
    "time-zone-subtitle": "Manage your time zone",
    "time-zone-hint": "Choose the time zone you'd like to use."
  },
  "home": {
    "welcome-title": "Welcome to {org}, {city}",
    "welcome-subtitle": "Let's get started with a tour of your dashboard. Discover how to make the most of your workspace.",
    "how-to": "How to?",
    "send-msg-btn": "Send message",
    "create-page-btn": "Create page",
    "import-contacts-btn": "Import contacts",
    "search-contacts-btn": "Search contacts",
    "complete-your-profile": "Complete your profile",
    "complete-your-profile-text": "Update profile information",
    "verify-phone-number": "Verify phone number",
    "verify-email": "Verify your email",
    "add-user-avatar": "Add user avatar",
    "update-your-bio": "Update your bio",
    "add-first-name": "Add your first name",
    "add-last-name": "Add your last name",
    "action-box-title": "Action box",
    "action-box-pending": "Pending Invitations",
    "action-box-invited-by": "Invited by ",
    "insights-title": "Insights",
    "insights-add": "Add Insight",
    "teammates-title": "Team Members",
    "teammates-subtitle": "Invite your team members",
    "teammates-invite-button": "Invite",
    "teammates-list-button": "Manage Accounts"
  },
  "table-columns": {
    "id": "ID",
    "first-name": "First Name",
    "last-name": "Last Name",
    "username": "Username",
    "email": "Email",
    "phone-number": "Phone number",
    "phone-number-verified-at": "Phone number verified at",
    "created-at": "Created at",
    "updated-at": "Updated at",
    "actions": "Actions"
  },
  "bulk-import": {
    "example-headers": {
      "column-name": "Column Name",
      "column-desc": "Description",
      "column-format": "Format",
      "column-example": "Example",
      "column-required": "Required",
      "column-add-new": "Add new column"
    },
    "update-duplicates": "Update duplicate items in DB",
    "skip-rows": "Skip rows",
    "skip-rows-info": "Skip {count} rows of the file| Skip {count} row of the file | Skip {count} rows of the file",
    "first-row-is-header": "First row of the remaining file is header",
    "confirm-import": "Confirm import data",
    "import-text": "Are you sure you want to import the data?",
    "import-notification": "Your data has been sent for processing. You will receive a notification when the process is complete.",
    "download": "Download csv sample",
    "drop-file": "Drop file here or",
    "browse": "browse",
    "file-size-error": "File size exceeds the maximum allowed size of 1MB",
    "file-type-error": "File type is not allowed. Please upload only .xlsx, .xls, .csv suffix files",
    "only-one-file": "You can only upload one file",
    "step-1": "Getting Started",
    "step-1-title": "Get ready to import your data",
    "step-1-subtitle": "The following columns are required, the rest are optional",
    "step-2": "Upload File",
    "step-2-title": "Choose file to upload",
    "step-2-subtitle": "Allowed file types: .csv, .xlsx, .xls",
    "step-3": "Column Mapping",
    "step-3-title": "Map Header Columns",
    "step-3-subtitle": "Confirm each column is mapped correctly",
    "step-4": "Preview data and import",
    "step-4-title": "Check column values",
    "step-4-subtitle": "Confirm each value is mapped correctly",
  }
}
