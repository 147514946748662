import { defineStore } from 'pinia'
import { api } from 'boot/axios'
import { LocalStorage } from 'quasar'
import { useOrganizationStore } from 'stores/organization';
import { Notify, Cookies } from 'quasar'
import { i18n } from 'boot/i18n';
import { useAppUtils } from "@/composables/appUtils";
import { getCssVar } from 'quasar'

export const useUserStore = defineStore('user', {
  id: 'user',
  state: () => ({
    items: [],
    allItems: [],
    item: {},
    user: null,
    userByEmail: null,
    availableRoles: [],
    userRoles: [],
    pendingInvitations: [],
    me: LocalStorage.getItem('me') || null,
    token: Cookies.get('authToken') || null,
    loading: false,
    loadingUser: false,
    loadingPhone: false,
    loadingEmail: false,
    loadingInvitations: false,
    loadingUserRoles: false,
    resetPasswordURL: null,
    setPasswordURL: null,
    resetEmailMessage: null,
    pagination: {
      page: 1,
      orderDir: "desc",
      deleted: false,
      search: "",
      orderBy: "id",
      rowsPerPage: 12,
      rowsNumber: 1
    },
    userSettings: {
      isDarkTheme: LocalStorage.getItem('darkTheme') || false,
      language: LocalStorage.getItem('language') || 'en-US',
      timezone: LocalStorage.getItem('timezone') || 'Europe/Berlin',
      dateFormat: LocalStorage.getItem('dateFormat') || 'DD.MM.YYYY',
      timeFormat: LocalStorage.getItem('timeFormat') || 'HH:mm',
      useGrid: LocalStorage.getItem('useGrid') || false,
      activeTab: LocalStorage.getItem('activeTab') || 'details',
      primaryColor: LocalStorage.getItem('primaryColor') || getCssVar('primary')
    }
  }),

  getters: {
    isLoggedIn: () => {
      return Cookies.has('authToken') ? true : false
    },
    getToken: () => {
      return Cookies.get('authToken')
    },
    cookieOptions: () => {
      let cookiesOptions = {
        path: '/'
      }
      if (import.meta.env.VITE_APP_NODE_ENV !== 'local') {
        cookiesOptions.domain = import.meta.env.VITE_APP_DOMAIN
        cookiesOptions.sameSite = 'None'
        cookiesOptions.secure = true
      }
      return cookiesOptions
    },
    defaultDateTimeFormat: (state) => {
      return `${state.userSettings.dateFormat} ${state.userSettings.timeFormat}`
    },

    userOrganizationPrefix: () => {
      const organizationStore = useOrganizationStore();
      return organizationStore.myOrganization.api_prefix
    },

    tableColumns: () => {
      const { formatDate } = useAppUtils();
      return [
        { name: 'id', label: i18n.t('table-columns.id'), align: 'left', field: 'id', },
        { name: 'firstName', label: i18n.t('table-columns.first-name'), align: 'center', field: 'firstName', format: (row) => row || 'N/A' },
        { name: 'lastName', label: i18n.t('table-columns.last-name'), field: 'lastName', format: (row) => row || 'N/A' },
        { name: 'username', label: i18n.t('table-columns.username'), field: 'username' },
        { name: 'email', label: i18n.t('table-columns.email'), align: 'center', field: 'email', format: (row) => row || 'N/A' },
        { name: 'phone_number', label: i18n.t('table-columns.phone-number'), field: 'phone_number', format: (row) => row || 'N/A' },
        { name: 'updated_at', label: i18n.t('table-columns.updated-at'), field: 'updated_at', align: 'center', format: (row) => formatDate(row) },
        { name: 'actions', label: i18n.t('table-columns.actions'), field: '', align: 'right', }
      ]
    },
    filterColumns: () => {
      return [
        { label: i18n.t('table-columns.email'), value: "email" },
        { label: i18n.t('table-columns.created-at'), value: "created_at" },
        { label: i18n.t('table-columns.updated-at'), value: "updated_at" },
        { label: i18n.t('table-columns.first-name'), value: "firstName" },
        { label: i18n.t('table-columns.last-name'), value: "lastName" },
        { label: i18n.t('table-columns.username'), value: "username" }
      ]
    },
    exportColumns: () => {
      return [
        { label: i18n.t('table-columns.id'), value: 'id' },
        { label: i18n.t('table-columns.first-name'), value: 'firstName' },
        { label: i18n.t('table-columns.last-name'), value: 'lastName' },
        { label: i18n.t('table-columns.username'), value: 'username' },
        { label: i18n.t('table-columns.email'), value: 'email' },
        { label: i18n.t('table-columns.phone-number'), value: 'phone_number' },
        { label: i18n.t('table-columns.created-at'), value: 'created_at' },
        { label: i18n.t('table-columns.updated-at'), value: 'updated_at' }
      ]
    }
  },

  actions: {
    async getItems() {
      this.loading = true
      try {
        const { rowsPerPage, ...rest } = this.pagination
        const newPagin = { perPage: rowsPerPage, ...rest }
        const { data } = await api.get(this.userOrganizationPrefix + '/users', { params: { ...newPagin } })
        this.loading = false
        this.pagination.rowsNumber = data.total
        this.pagination.rowsPerPage = data.per_page
        this.pagination.page = data.current_page
        this.items = data.data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async getAllItems() {
      this.loading = true
      try {
        const { data } = await api.get(this.userOrganizationPrefix + '/users', { params: { all: true } })
        this.loading = false
        this.allItems = data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async getActions() {
      this.loadingInvitations = true
      try {
        const { data } = await api.get(this.userOrganizationPrefix + '/home')
        this.loadingInvitations = false
        this.pendingInvitations = data.pendingInvitations
      }
      catch (error) {
        this.loadingInvitations = false
        throw error
      }
    },

    async loadMyUser() {
      this.loading = true
      try {
        const { data } = await api.get('/me')
        this.loading = false
        LocalStorage.set('me', data)
        const { setMyOrganization } = useOrganizationStore();
        setMyOrganization(data)
        this.me = data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    setActiveTab(tab) {
      this.userSettings.activeTab = tab
      LocalStorage.set('activeTab', tab)
    },

    toggleGrid() {
      this.userSettings.useGrid = !this.userSettings.useGrid
      LocalStorage.set('useGrid', this.userSettings.useGrid)
    },

    setPrimaryColor(color) {
      this.userSettings.primaryColor = color
      LocalStorage.set('primaryColor', color)
    },

    toggleStoreTheme() {
      this.userSettings.isDarkTheme = !this.userSettings.isDarkTheme
      LocalStorage.set('darkTheme', this.userSettings.isDarkTheme)
    },

    setUserLanguage(lang) {
      this.userSettings.language = lang
      LocalStorage.set('language', lang)
    },

    setUserTimezone(tz) {
      this.userSettings.timezone = tz
      LocalStorage.set('timezone', tz)
    },


    setUserDateFormat(df) {
      this.userSettings.dateFormat = df
      LocalStorage.set('dateFormat', df)
    },

    setUserTimeFormat(tf) {
      this.userSettings.timeFormat = tf
      LocalStorage.set('timeFormat', tf)
    },

    async searchUsersByEmail(email) {
      this.loading = true
      try {
        const { data } = await api.get(this.userOrganizationPrefix + '/users/search', { params: { email } })
        this.loading = false
        this.userByEmail = data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async checkPhoneNumber(phone_number) {
      this.loadingPhone = true
      try {
        await api.post('services/communication/number_lookup', { phone_number })
        this.loadingPhone = false
        return true
      }
      catch (error) {
        this.loadingPhone = false
        return false
      }
    },

    async getAvailableRoles() {
      this.loadingUserRoles = true
      try {
        const { data } = await api.get('roles/', { params: { all: true } })
        this.loadingUserRoles = false
        this.availableRoles = data
      }
      catch (error) {
        this.loadingUserRoles = false
        throw error
      }
    },

    async getUserRoles(id, onlyId = false) {
      this.loading = true
      try {
        const { data } = await api.get(this.userOrganizationPrefix + '/users/' + id + '/roles', { params: onlyId ? { onlyId: true } : {} })
        this.loading = false
        this.userRoles = data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async createUser(user) {
      this.loading = true
      try {
        await api.post(this.userOrganizationPrefix + '/users/', user)
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async getUser(id) {
      this.loadingUser = true
      try {
        const { data } = await api.get(this.userOrganizationPrefix + '/users/' + id)
        this.loadingUser = false
        this.item = data
      }
      catch (error) {
        this.loadingUser = false
        throw error
      }
    },

    async updateUser(user) {
      this.loading = true
      try {
        await api.put(this.userOrganizationPrefix + '/users/' + user.id, user)
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async updateProfile(user) {
      this.loading = true
      try {
        const { data } = await api.patch('users/profile/update', user)
        this.loading = false
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.updateLocalUser(data.data)
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async changeUserPassword(item) {
      this.loading = true
      try {
        const { data } = await api.patch('users/profile/password', item)
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async changePhoneNumber(item) {
      this.loadingPhone = true
      try {
        const { data } = await api.patch('users/profile/phone_number', item)
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loadingPhone = false
      }
      catch (error) {
        this.loadingPhone = false
        throw error
      }
    },

    async changeEmail(item) {
      this.loadingEmail = true
      try {
        const { data } = await api.patch('users/profile/email', item)
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loadingEmail = false
      }
      catch (error) {
        this.loadingEmail = false
        throw error
      }
    },

    async login(user) {
      this.loading = true
      const orgStore = useOrganizationStore();
      try {
        const { data } = await api.post('/auth/login', user)
        this.loading = false
        orgStore.myOrganization = null
        this.me = null
        api.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.token
        Cookies.set('authToken', data.data.token, { expires: 7, ...this.cookieOptions })
        return data
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },


    async logout() {
      this.loading = true
      try {
        await api.post('/auth/logout')
        Cookies.remove('authToken', this.cookieOptions)
        LocalStorage.removeItem('me')
        LocalStorage.removeItem('myOrganization')
        this.loading = false
      }
      catch (error) {
        this.loading = false
        Cookies.remove('authToken', this.cookieOptions)
        LocalStorage.removeItem('me')
        LocalStorage.removeItem('myOrganization')
        throw error
      }
    },

    async forgotPassword(email) {
      this.loading = true
      try {
        const { data } = await api.post('/auth/password/forgot', email)
        this.resetEmailMessage = data.data.status
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async checkSetToken(id, token) {
      this.loading = true
      try {
        const { data } = await api.post(`/users/${id}/password/edit/${token}`)
        this.setPasswordURL = data.data.submit_url
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async checkResetToken(id, token) {
      this.loading = true
      try {
        const { data } = await api.post(`/auth/password/reset/${id}/${token}`)
        this.resetPasswordURL = data.data.submit_url
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async resetPassword(url, body) {
      this.loading = true
      try {
        const { data } = await api.post(url, body)
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    async setPassword(url, body) {
      this.loading = true
      try {
        const { data } = await api.post(url, body)
        Notify.create({
          message: data.message,
          type: 'positive',
        })
        this.loading = false
      }
      catch (error) {
        this.loading = false
        throw error
      }
    },

    updateLocalUser(user) {
      const newUser = { ...this.me, ...user }
      LocalStorage.set('me', newUser)
    }
  },
})
